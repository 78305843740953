//exporting a variable
export var base_auth_token = "TmV1cm9uOk5ldXJvQDU0MzIx";
// export var api_base_url = "http://localhost:3005/";
// export var site_url = "http://localhost:3002";
// export var api_base_url = "https://demo-api.f4tek.com/";
// export var site_url = "https://demo.f4tek.com";
export var api_base_url = "https://prodapi.f4tek.com/";
export var site_url = "https://akalmortgages.f4tek.com";

export var callrail_api_key = "9db8604da634210674ed60039ed00cbc";

//export var api_base_url = 'https://api.f4tek.com/';
//export var site_url = "https://lms.f4tek.com";

export var perPage = 10;

export var statusList = ["New", "Converted", "In process", "Cold", "Hot"];
export var stagingList = ["to begin", "Contacted", "Documented", "Completed"];

export var statusKeyList = ["Inactive", "Active"];

export function getSessionToken() {
  var user_details = localStorage.getItem("lmsuser");
  if (
    user_details != "undefined" &&
    user_details != null &&
    user_details != ""
  ) {
    var lms_usersession = JSON.parse(user_details);
    if (lms_usersession.hasOwnProperty("token")) {
      return lms_usersession.token;
    }
  }
  return false;
}

export function setFlashMessage(message) {
  localStorage.setItem("successMsg", message);
  return true;
}

export function getFlashMessage(message = "") {
  if (!message) message = localStorage.getItem("successMsg");
  console.log("message", message);
  return (
    <div>
      {message ? (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          {message}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export const getName = (lead) => {
  let first_name = "";
  let last_name = "";
  if (lead.first_name) {
    first_name = lead.first_name?.charAt(0)?.toUpperCase()
      ? lead.first_name?.charAt(0)?.toUpperCase()
      : "";
    first_name += lead.first_name?.slice(1) ? lead.first_name?.slice(1) : "";
  }
  if (lead.last_name) {
    last_name = lead.last_name?.charAt(0)?.toUpperCase()
      ? lead.last_name?.charAt(0)?.toUpperCase()
      : "";
    last_name += lead.last_name?.slice(1) ? lead.last_name?.slice(1) : "";
  }
  const full_name = first_name + " " + last_name;
  return full_name;
};
