import React from "react";

export const ToggleInput = ({ field, label, onChange, value, error }) => {
  const onChangeValue = (e) => {
    onChange && onChange(e.target.value, field);
  };
  return (
    <div>
      <div className="switch-btn btn-checkbox">
        <input
          type="checkbox"
          name={field}
          checked={value ? true : false}
          id={field}
          value={value}
          data-index={value}
          onChange={onChangeValue}
        />
        <label for={field}>{label}</label>
      </div>
      <span className="error">{error}</span>
    </div>
  );
};
